<template>
  <div class="d-inline-flex justify-end col-12 align-center" v-if="display">
    <div v-if="disable">
      <span class="text-primary page-checked">PAGE CHECKED</span>
    </div>
    <v-btn
      v-if="canCheck && !disable"
      :id="`checkingButton-${id}`"
      :icon="pageItems.length == 1"
      outlined
      @click="check()"
      :color="checkedByCurrentUser ? 'warning' : 'success'"
    >
      <span v-if="pageItems.length > 1 && canCheck">
        Check page
      </span>
      <v-icon>{{ checkedByCurrentUser ? "mdi-close" : "mdi-check" }}</v-icon>
    </v-btn>
    <div class="d-flex flex-column ml-1">
      <check-stamp
        :id="`checkedByCRA-${id}`"
        v-if="checked('CRA')"
        :color="'primary'"
        :name="'CRA'"
      ></check-stamp>
      <check-stamp
        :id="`checkedByDM-${id}`"
        v-if="checked('DM')"
        :color="'info'"
        :name="'DM'"
      ></check-stamp>
    </div>
  </div>
</template>

<script>
import { Checking } from "spiral";
import tag from "../../mixin/tag";
import CheckStamp from "./CheckStamp.vue";

export default {
  components: { CheckStamp },
  data() {
    return {
      startNewChecking: true
    };
  },
  props: ["pageItem", "page"],
  computed: {
    id() {
      return this.pageItem
        ? this.pageItem.variableName
        : this.getLabel("mlstring", {}, this.page?.name, this.$i18n).replace(
            / /g,
            "-"
          );
    },
    display() {
      return (
        !this.isStudioMode &&
        this.currentPage &&
        this.currentPage.items.length > 0
      );
    },
    pageItems() {
      return this.pageItem
        ? [this.pageItem]
        : this.currentInterview
            ?.getItemsForPage(this.page)
            ?.map(i => i.pageItem) ?? [];
    },
    canCheck() {
      return this.authorizationManager.canCheck(this.currentInterview);
    },
    disable() {
      return this.pageItems?.length > 1 && this.checkedByCurrentUser;
    },
    checking() {
      return this.pageItems?.map(
        i =>
          this.currentInterview?.processes?.find(
            c => c.action == "checking" && c.pageItem == i
          ) ?? new Checking(i)
      );
    },
    checkedByCurrentUser() {
      return this.checked(this.tagName);
    }
  },
  methods: {
    checked(name) {
      return this.checking?.every(c => c.isChecked(name));
    },
    async check() {
      const name = this.tagName;
      if (name) {
        if (this.startNewChecking) {
          this.startNewChecking = false;
          const processes = this.checking
            .filter(c => !c.isChecked(name) || this.pageItems.length == 1)
            .map(c => this.checkOne(c, name));
          await this.pushSaveProcesses(processes).finally(
            () => (this.startNewChecking = true)
          );
        }
      } else this.setError(this.$t("checkingError"));
    },
    checkOne(checking, name) {
      return checking.isChecked(name)
        ? checking.uncheck(name)
        : checking.check(this.currentUser.userid, name);
    }
  },
  mixins: [tag]
};
</script>
