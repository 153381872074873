<template>
  <div
    class="d-flex justify-end align-center m-1"
    :class="{ 'justify-space-around mx-0': mobile }"
  >
    <v-chip
      id="currentPatientSiteCode"
      label
      class="white--text"
      :class="{ col: mobile }"
      :color="color"
    >
      {{ $t("domain.noSite") }}: {{ currentPatient.site.siteCode }}
    </v-chip>
    <v-chip
      id="currentPatientCode"
      label
      class="ml-1 white--text"
      :class="{ col: mobile }"
      :color="color"
    >
      {{ $t("domain.noPatient") }}: {{ patientCode }}
    </v-chip>
    <v-chip
      id="currentPatienFrozen"
      v-if="this.currentPatient.site.frozen"
      label
      class="white--text"
      :class="{ col: mobile }"
      :color="color"
    >
      {{ $t("frozen") }}
    </v-chip>
  </div>
</template>

<script>
import { formatCode } from "spiral";
export default {
  computed: {
    patientCode() {
      const currentPatientCode = formatCode(
        this.currentPatient,
        this.currentStudy.options
      );
      return currentPatientCode == "" ? this.$t("pending") : currentPatientCode;
    },
    frozen() {
      return this.currentPatient.site.frozen;
    },
    color() {
      return this.frozen ? "danger" : "info";
    }
  },
  i18n: {
    messages: {
      en: { pending: "Pending", frozen: "frozen" },
      fr: { pending: "En attente", frozen: "gelé" }
    }
  }
};
</script>
