<template>
  <v-badge :color="color" icon="mdi-check" avatar bordered overlap>
    <v-chip x-small :color="color">{{ name }}</v-chip>
  </v-badge>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
};
</script>
