import { render, staticRenderFns } from "./InformationComment.vue?vue&type=template&id=9bfad514&"
import script from "./InformationComment.vue?vue&type=script&lang=js&"
export * from "./InformationComment.vue?vue&type=script&lang=js&"
import style0 from "./InformationComment.vue?vue&type=style&index=0&id=9bfad514&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports