import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    tagName() {
      return this.isInRole("cra", "developer")
        ? "CRA"
        : this.isInRole("datamanager")
        ? "DM"
        : undefined;
    }
  }
};
