<template>
  <v-badge
    v-if="nbrQueries > 0"
    :content="`${nbrOpenQueries ? nbrOpenQueries : 0}/${nbrQueries}`"
    :value="nbrQueries"
    overlap
    :color="nbrOpenQueries > 0 ? 'danger' : 'success'"
  >
    <v-btn icon outlined color="info" @click="goToItemDetails()"> Q? </v-btn>
  </v-badge>
</template>

<script>
export default {
  props: ["pageItem"],
  computed: {
    nbrOpenQueries() {
      return (
        this.currentInterview
          ?.availableProcesses(this.currentWorkflow)
          .filter(p => p.action == "query" && p.pageItem == this.pageItem)
          .filter(p => !p.isClosed && !p.isCanceled).length ?? 0
      );
    },
    nbrQueries() {
      return (
        this.currentInterview
          ?.availableProcesses(this.currentWorkflow)
          .filter(p => p.action == "query" && p.pageItem == this.pageItem)
          .length ?? 0
      );
    }
  },
  methods: {
    goToItemDetails() {
      this.$router.push({
        name: "Item details",
        params: {
          patientCode: this.$route.params.patientCode,
          variableName: this.pageItem.variableName
        },
        query: {
          visit: this.$route.query.visit,
          page: this.$route.query.page
        }
      });
    }
  }
};
</script>
