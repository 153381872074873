var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('span',{staticClass:"d-flex flex-row align-center"},[_vm._t("pre-wording"),(_vm.wording)?_c('div',[(_vm.wording)?_c('lang-label',{staticClass:"col text-h6",attrs:{"labels":_vm.wording}}):_vm._e(),_vm._t("header")],2):_vm._e()],2),_c('div',{staticClass:"d-lg-inline-flex align-md-center col p-0 value-input"},[_c('div',_vm._g({class:[
        'col',
        _vm.rightAccessAudittrail ? 'audit-trail' : '',
        _vm.wording ? 'text-lg-right' : ''
      ]},
        _vm.rightAccessAudittrail
          ? { click: () => _vm.$emit('itemselected', _vm.name) }
          : {}
      ),[_c('lang-label',{class:[
          'col',
          _vm.wording ? 'd-flex justify-center justify-lg-end' : ''
        ],attrs:{"labels":_vm.modifier.leftWording}})],1),(!_vm.isInfo)?_c('value-input',{class:['col-lg-4', _vm.wording ? 'd-flex justify-center' : ''],attrs:{"disabled":_vm.disabled,"value":_vm.value,"name":_vm.name,"type":_vm.type,"metadata":_vm.metadata,"labels":_vm.wording,"expanded":true,"classes":_vm.classes},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e(),_c('div',_vm._g({staticClass:"col",class:_vm.rightAccessAudittrail ? 'audit-trail' : ''},
        _vm.rightAccessAudittrail
          ? { click: () => _vm.$emit('itemselected', _vm.name) }
          : {}
      ),[_c('lang-label',{class:[
          'col',
          _vm.wording ? 'd-flex justify-center justify-lg-start' : ''
        ],attrs:{"labels":_vm.modifier.rightWording}})],1),_vm._t("append")],2),_c('div',[(_vm.isEpro)?_vm._t("invalid-feedback"):_vm._e(),_vm._t("footer")],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }